import Popup from '@/components/popup.vue';
import { ITable } from '@/components/table_v2';
import Table from '@/components/table_v2/table.vue';
import store from '@/store';
import { IStateSpace } from '@/store/modules/spaces';
import { ISpacesTranslations } from '@/translations';
import sort from '@/utils/sort';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { spaceActionPopup, table } from '.';

@Component({
  name: 'HeadCounter',
  components: { Table, Popup }
})
class HeadCounter extends Vue {
  @Action('fetchSpaces') public fetch!: (params: { page: number, pageSize: number, search?: string }) => void;

  @Mutation('selectSpace') public selectSpace!: (space: IStateSpace) => void;

  @Getter('getSpaces') public spaces!: IStateSpace[];

  @Getter('getSpacesTranslations') public translations!: ISpacesTranslations;
  public table = {} as ITable<IStateSpace>;
  public spaceActionPopup = spaceActionPopup;
  public created() {

    this.fetch({ page: 1, pageSize: 20 });

    this.table = table(this.translations);
    this.table.onSelectedItem = this.onSpaceSelected;

    store.subscribe((mutation: MutationPayload) => {

      if (mutation.type === 'setLanguage') {

        this.table = { ...this.table, ...table(this.translations) };

      }

      if (mutation.type !== 'selectSpace') {

        const key = Object.keys(this.table.order) as Array<Extract<IStateSpace, string>>;
        const field = key[0];
        const order = this.table.order[key[0]];

        if (mutation.type !== 'setSpacesLoading') {
          this.table = {
            ...this.table,
            data: sort(field, order, this.spaces)
          };
        }

        // if (mutation.type === 'newSpace') {
        //   this.triggerPopup({
        //     message: 'this.translations.created_success',
        //     type: 'success'
        //   });
        // }
      }
    });

  }
  public onSpaceSelected(space: IStateSpace) {
    this.selectSpace(space);
  }
  public triggerPopup({ message, type }: { message: string, type: string }) {
    this.spaceActionPopup = {
      ...this.spaceActionPopup,
      isActive: true,
      message,
      type,
    };
  }
}

export default HeadCounter;
